export default {
  address: "台北市南京東路二段178號13樓",
  googleSrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.4773426715087!2d121.5328906150067!3d25.051805383963973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a961b2d15e53%3A0xa9b9372e74af76da!2zMTA0OTHlj7DljJfluILkuK3lsbHljYDljZfkuqzmnbHot6_kuozmrrUxNzjomZ8!5e0!3m2!1szh-TW!2stw!4v1612860471523!5m2!1szh-TW!2stw",
  googleLink: "https://goo.gl/maps/Mf8Yp8KLs9N3MFBb7",
  phone: "02-2516-1888",
  fbLink: "https://www.facebook.com/jointgroup.tpe/",
  fbMessage: "https://m.me/jointgroup.tpe/",
  caseName: "聯悅臻",
  indigatorLength: 10,

  houseInfos: [
    ["投資興建", "聯悦建設股份有限公司"],
    ["企劃銷售", "聯悦建設股份有限公司"],
    ["設計規劃", "永豐建築師事務所"],
    ["基地位置", "梧棲區市鎮南段133地號"],
    ["接待中心", "台北市南京東路二段178號13樓"],
    ["洽詢專線", "02-2516-1888"]
  ],

  gtmCode: ["M9XNFHL"], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: "預約賞屋",
    subTitle: ""
  }
};
